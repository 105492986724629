(function () {
    angular.module('informaApp')
        .component('featureCardList', {
            templateUrl: 'components/new-feature-popup/feature-card-list/template.html',
            controller: NewFeaturePopup,
            bindings: {
                featureList: '<',
                onCardOpenLinkClick: '<'
            }
        });

    function NewFeaturePopup() {
    }
})();